<template>
  <div class="buyCoin-wrap">
    <van-nav-bar :border="false" left-text="" @click-left="back" left-arrow>
      <div slot="left">
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot="title" class="ub ub-cen">
        Buy USDT
        <div class="margin-left8 ub ub-cen">
          <img src="@/assets/images/walltes/arrow-down.svg" alt="">
        </div>
      </div>
      <div slot="right">
        <img @click="goHistory" src="@/assets/images/walltes/list.svg" alt="" srcset="">
      </div>
    </van-nav-bar>
    <div class="buyCoin-main">
      <div class="text-c main-text-2 font12">
        1 USDT ≈ 1.05 USD
      </div>
      <div class="ub ub-btw" style="margin-top: 140px;">
        <div></div>
        <div class="ub ub-h-e">
          <span class="font48">{{ value||0 }}</span>
          <span class="font16">{{type1}}</span>
          <div class="margin-left8 ub ub-cen" style="margin-bottom: 4px;">
            <img src="@/assets/images/walltes/arrow-down.svg" alt="">
          </div>
        </div>
        <div>
          <div class="margin-left8 ub ub-cen" @click="changeUnit">
            <img src="@/assets/images/walltes/exchange-c.svg" alt="">
          </div>
          <p class="font12 main-text-3">{{type2}}</p>
        </div>
      </div>
      <div class="footer-box">
        <div class="keyboard-wrap">
          <van-number-keyboard
            v-model="value"
            :show="showKeyBoard"
            :maxlength="9"
            extra-key="."
            @blur="showKeyBoard = true"
          />
        </div>
        <div class="buy-btn-box ub ub-cen">
          <div class="primary-btn-color ub ub-cen font16" @click="goSelectionPaymentMethod">
            Buy USDT
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
      type1: 'USD',
      type2: 'USDT',
      value: '',
      showKeyBoard: true
    }
  },
  methods: {
    changeUnit () {
      [this.type1, this.type2] = [this.type2, this.type1]
    },
    goSelectionPaymentMethod () {
      this.$router.push({
        path: '/c2c/SelectionPaymentMethod',
        query: {
          amount: this.value
        }
      })
    },
    goHistory () {
      console.log('goHistory')
      this.$router.push('/history/walltes/deposit')
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.buyCoin-main{
  padding: 30px;
  box-sizing: border-box;
  .keyboard-wrap{
    position: relative;
    .van-number-keyboard{
      position: relative;
      background-color: #fff;
    }
  }
  .footer-box{
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .buy-btn-box{
    height: 96px;
    padding: 30px;
    .primary-btn-color{
      width: 100%;
      height: 96px;
      border-radius: 4px;
    }
  }
}
</style>
